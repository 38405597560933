import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const Data = ({participant}) => {

  const getSettings = () => participant?.tournament?.definition_vote_settings?.settings || {}
  const getSettingsScores = () => getSettings().scores || []
  const getSettingsPenalty = () => getSettings().penalty || []

  const getScoreTitle = key => getSettingsScores().find(s => s.key === key)?.title
  const getPenaltyTitle = key => getSettingsPenalty().find(s => s.key === key)?.title

  const difficul = ["1719679111585", "1721217140586", "1721502168412", "1721501824051", "1726412198719", "1726412157822"]

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section>
                <div className="about-team-pages-area sc-pt-130 sc-md-pt-20 sc-pb-180 sc-md-pb-20">
                    <div className="container">
                        <div className="row">
                            <h5>{participant?.group_name_short}</h5>
                            <h5>{participant?.title}</h5>
                            <h5>{participant?.coach}</h5>
                            <h5>{participant?.school}</h5>
                            <h5><b>Загалом: {participant?.total} балів</b></h5>
                            {!!participant?.total_scores && (<h5><b>Бали: {participant?.total_scores}</b></h5>)}
                            {Object.keys(participant?.total_scores_details || {}).map(sk => (
                                <h6>{getScoreTitle(sk)}: {(participant?.total_scores_details[sk].reduce( ( p, c ) => p + c, 0 ) / participant?.total_scores_details[sk].length).toFixed(2)}</h6>
                            ))}

                            {!!participant?.total_penalty && (<h5><b>Штрафи: {participant?.total_penalty}</b></h5>)}
                            {Object.keys(participant?.total_penalty_details || {}).map(sk => (
                                <h6>{getPenaltyTitle(sk)}: {(participant?.total_penalty_details[sk].reduce( ( p, c ) => p + c, 0 ) / participant?.total_penalty_details[sk].length).toFixed(2)}</h6>
                            ))}

                            {!!participant?.rank && (<h5>Місце: {participant?.rank}</h5>)}

                            {participant?.tournament?.id == 33 && (participant?.votes || []).map((vote, index) => (
                                <>
                                    {difficul.filter(d => vote.scores[d]).map(d => (
                                          <>
                                              <b>{vote.profile ? vote.profile : `Суддя №${index + 1}`}</b>
                                              <ul>
                                                  {vote.scores[d].map(ditem => (
                                                      <li style={{marginBottom: '15px'}}>
                                                          {ditem.label}: <b><span style={{color: '#0979e5'}}>{ditem.score.toFixed(2)}</span></b><br />
                                                          {ditem.additional?.missing && (<span style={{color: 'red'}}>Missing: {ditem.additional?.missing.join(', ')}</span>)}
                                                          {ditem.additional?.elements && (
                                                              <table style={{width: '100%'}}>
                                                                  <thead>
                                                                      <tr>
                                                                          <td>Code</td>
                                                                          <td>Element</td>
                                                                          <td>Value</td>
                                                                          <td>Downgrade</td>
                                                                      </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                  {(participant.posa_form_elements?.form || []).map(pfe => (
                                                                      <tr>
                                                                          <td>{pfe.code}</td>
                                                                          <td>{pfe.element}</td>
                                                                          <td><span style={{color: 'green'}}>{pfe.value}</span></td>
                                                                          <td><span style={{color: 'red'}}>{ditem.additional?.elements.find(ae => ae.code == pfe.code)?.downgrade || 0}</span></td>
                                                                      </tr>
                                                                  ))}
                                                                  </tbody>
                                                              </table>
                                                          )}
                                                          {ditem.label.includes('Combination') && (
                                                              <table style={{width: '100%'}}>
                                                                  <thead>
                                                                      <tr>
                                                                          <td>Codes</td>
                                                                          <td>Elements</td>
                                                                          <td>PTS</td>
                                                                      </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                  {(participant.posa_form_elements?.combinations || []).map((pfc, pfci) => (
                                                                      <tr>
                                                                          <td>{pfc.map(t => t.code).join(', ')}</td>
                                                                          <td>{pfc.map(t => t.element).join(', ')}</td>
                                                                          <td>
                                                                              {((ditem.additional?.combinations || {})[pfci] || {}).accepted ? '✅' : '❌'}
                                                                          </td>
                                                                      </tr>
                                                                  ))}
                                                                  </tbody>
                                                              </table>
                                                          )}
                                                      </li>
                                                  ))}
                                              </ul>
                                          </>
                                    ))}
                                </>
                            ))}
                        </div>

                        {!!participant?.tournament?.enable_detailed_votes_for_participants && (
                        <table style={{width: '100%'}}>
                            <tbody>
                                {(participant?.votes || []).map((vote, index) => (
                                    <React.Fragment key={vote.id}>
                                        <tr>
                                            <td colSpan={2}>
                                                <b>
                                                    {vote.profile ? `Суддя ${vote.profile}` : (vote.judge?.judge?.name ? vote.judge?.judge?.name : `Суддя №${index + 1}`)}
                                                </b>
                                            </td>
                                        </tr>
                                        {!!vote.comments && (
                                            <tr>
                                                <td colSpan={2}><b>Коментар судді:</b> {vote.comments}</td>
                                            </tr>
                                        )}
                                        {!!vote.places && !Array.isArray(vote.places) && Object.keys(vote.places).map((placeKey, p) => (
                                            <React.Fragment key={p}>
                                                    <tr key={placeKey} style={{fontSize: '0.9em'}}>
                                                        <td style={{color: 'green'}} colSpan={1}>Місце {vote.places[placeKey].place.toFixed(0)}</td>
                                                        <td></td>
                                                    </tr>
                                            </React.Fragment>
                                        ))}
                                        {!!vote.scores && !Array.isArray(vote.scores) && Object.keys(vote.scores).map((scoreKey, p) => (
                                            <React.Fragment key={p}>
                                                <tr>
                                                    <td colSpan={2} align="center"><b>{getScoreTitle(scoreKey)}</b></td>
                                                </tr>
                                                {vote.scores[scoreKey].filter(f => f.score).map((scoreRow, scoreIndex) => (
                                                    <tr key={scoreIndex} style={{fontSize: '0.9em'}}>
                                                        <td colSpan={1}>{scoreRow.label}</td>
                                                        <td style={{color: 'green'}}>{scoreRow.score.toFixed(2)} з {scoreRow.max}</td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                        {!!vote.penalty && !Array.isArray(vote.penalty) && Object.keys(vote.penalty).map((penaltyKey, p) => (
                                            <React.Fragment key={p}>
                                                <tr>
                                                    <td colSpan={2} align="center"><b>{getPenaltyTitle(penaltyKey)}</b></td>
                                                </tr>
                                                {vote.penalty[penaltyKey].filter(f => f.penalty).map((penaltyRow, penaltyIndex) => (
                                                    <tr key={penaltyIndex} style={{fontSize: '0.9em'}}>
                                                        <td colSpan={1}>{penaltyRow.label}</td>
                                                        <td style={{color: 'red'}}>{penaltyRow.penalty.toFixed(2)}</td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                        <tr>
                                            <td colSpan={2}>&nbsp;</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        )}
                        <div className="row">
                            <a href="https://arena.promo/insurance" target="_blank" style={{textDecoration: 'none', color: '#0979e5', margin: 'auto', textAlign: 'center', marginBotton: '20px'}}>
                                🚑 🏥 Придбати страховий поліс 🏥 🚑
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Data;
