import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import BlogForm from './BlogForm';
import Sidebar from './Sidebar';
import pagesBlogImg from '../../../assets/images/blog/pages_blog_img.jpg'
import detailsIcon from '../../../assets/images/icons/details_icon.png'
import detailsAuthor1 from '../../../assets/images/blog/details_author.png'
import detailsAuthor2 from '../../../assets/images/blog/details_author2.png'
import detailsAuthor3 from '../../../assets/images/blog/details_author3.png';

import axios from 'axios';
import { Routes, Route, useParams } from 'react-router-dom';


const BlogDetailsData = ({event}) => {

    const [ participants, setParticipants ] = useState([])
    const [ selected, setSelected ] = useState(null)
    const [ votes, setVotes ] = useState([])
    const [ grouped, setGrouped ] = useState([])

    useEffect(() => {
        if(!event?.tournament?.enable_votes_public_access && event.id != 3){
            setVotes([])
            setParticipants([])
            return
        }

        axios.get(`https://api-festival-play.arena.promo/api/tournaments/${event.id}/participants`).then(payload => {
            setVotes([])
            setParticipants(payload.data)
        })
    }, [event])

    useEffect(() => {
        if(!participants){
            setGrouped({})
            return
        }
        let groups = {}
        for(const item of participants){
            if(!groups[item.group_key]){
                groups[item.group_key] = []
            }

            groups[item.group_key].push(item)
        }
        for(const key in groups){
            groups[key] = groups[key].sort((a, b) => b.total - a.total)
        }

        setGrouped(groups)
    }, [participants])

    useEffect(() => {
        if(!event?.tournament?.enable_votes_public_access || !event?.tournament?.enable_detailed_votes_for_participants){
            setVotes([])
            return
        }

        if(!selected){
            setVotes([])
            return
        }

        axios.get(`https://api-festival-play.arena.promo/api/tournaments/${event.id}/votes/${selected}`).then(payload => {
            setVotes(payload.data)
        })
    }, [selected])

    const tournament = event.festival ?? event.air_gymnastics
    const playHost = event.festival ? 'festival' : 'air'
    const getSettings = () => tournament?.definition_vote_settings?.settings || {}
    const getSettingsScores = () => getSettings().scores || []
    const getSettingsPenalty = () => getSettings().penalty || []

    const getScoreTitle = key => getSettingsScores().find(s => s.key === key)?.title
    const getPenaltyTitle = key => getSettingsPenalty().find(s => s.key === key)?.title

    return (
        <>
            <section>
                <div className="sc-blog-details sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-item">
                                    <div className="blog-image">
                                          <img
                                              src={(tournament.media || [])[0]?.s3}
                                              alt={event?.name}
                                          />
                                    </div>
                                    <div className="blog-content p-0">
                                        <div className="blog_date">
                                            <ul>
                                                <li>{event.date.getDate()}</li>
                                                <li>
                                                    {event.date.toLocaleString('default', { month: 'long' })}
                                                </li>
                                            </ul>
                                        </div>
                                        {/*
                                        <div className="blog-list-menu list_menu sc-pt-20">
                                            <ul className="blog-list">
                                                <li><i className="ri-edit-fill"></i><Link smooth to="#"> Admin</Link></li>
                                                <li><i className="ri-chat-3-fill"></i><Link smooth to="#"> 05 Comment</Link></li>
                                            </ul>
                                        </div>
                                        */}
                                        <h3 className="blog-title sc-pt-15 sc-mb-15 fw-bold text-center">
                                              {event?.name}
                                        </h3>
                                    </div>
                                </div>
                                <div className="details-content-area sc-md-mb-50" style={{overflow: 'scroll'}}>
                                    <div className="details-content">
                                        <div className="description sc-mb-25">
                                            {(event.tournament?.description || '').split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </div>
                                        {!tournament?.finished && !tournament.block_registration_from_telegram && (
                                            <div className="blockquote">
                                                <div className="icon-image">
                                                    <img src={detailsIcon} alt="Icons" />
                                                </div>
                                                <div className="description sc-mb-25">
                                                    {(event.tournament?.payment_notes || '').split('\n').map((item, key) => {
                                                        return <span key={key}>{item}<br/></span>
                                                    })}
                                                </div>
                                                <span>Інструкції з оплати</span>
                                            </div>
                                        )}
                                        {!tournament?.finished && (
                                            <div className="description sc-mb-25">
                                                {(event.tournament?.registration_notes || '').split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br/></span>
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    {!tournament?.finished && (
                                        <a className="primary-btn2" smooth target="_blank" href={`https://t.me/${event.company?.telegram_bot || 'ArenaPromoBot'}`} ><span>Реєстрація</span></a>
                                    )}

                                    {!tournament?.finished && (tournament?.schedules || []).map(schedule => (
                                        <a className="primary-btn2" style={{marginLeft: '20px'}} smooth target="_blank" href={`https://play.${playHost}.arena.promo/schedules/${schedule.id}`} ><span>Онлайн табло {schedule.date}</span></a>
                                    ))}

                                    {!!event?.tournament?.enable_detailed_votes_for_participants && !!Object.values(grouped).length && event.id != 3 && (
                                        <p>
                                            Щоб дізнатись детальні оцінки треба натиснути на строку таблиці.
                                        </p>
                                    )}

                                    {!!Object.values(grouped).length && event.id != 3 && (
                                    <table style={{width: '100%'}}>
                                        <thead>
                                            <tr>
                                                <td><b>Учасник</b></td>
                                                {/*
                                                {getSettingsScores().map(score => score.title).concat(
                                                    getSettingsPenalty().map(penalty => penalty.title)
                                                ).map(v => (
                                                    <td key={v}><b>{v}</b></td>
                                                ))}
                                                */}
                                                <td><b>Загалом</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.values(grouped).map((items, i) => { return (
                                                <React.Fragment key={i}>
                                                    <tr>
                                                        <td colSpan={2} align="center"><i>{items[0]?.definition}</i></td>
                                                    </tr>
                                                    {items.filter(item => item.total).map((item, j) => {

                                                      let title = (item.title || '').substring(0, 64)
                                                      if(title){
                                                          title = `"${title}"`
                                                      }
                                                      title += ' ';

                                                      title += (tournament?.definition_participant_additional_parameters || [])
                                                          .filter(p => p.use_in_schedule)
                                                          .filter(p => p.type == 'buttons')
                                                          .filter(p => p.values[(item?.parameters || {})[p.key]])
                                                          .map((p, pi) => p.values[(item?.parameters || {})[p.key]])
                                                          .join(', ')

                                                      title += ' ';

                                                      title += (tournament?.definition_participant_additional_parameters || [])
                                                          .filter(p => p.use_in_schedule)
                                                          .filter(p => p.type == 'message')
                                                          .filter(p => (item?.parameters || {})[p.key])
                                                          .map((p, pi) => (item?.parameters || {})[p.key])
                                                          .join(', ')


                                                      return (
                                                        <React.Fragment key={j}>
                                                            <tr onClick={() => selected === item.id ? setSelected(null) : setSelected(item.id)}>
                                                                <td><b>{item?.participant?.name}</b></td>
                                                                {/*
                                                                {getSettingsScores().map(score => (item.total_scores_details || {})[score.key]).concat(
                                                                    getSettingsPenalty().map(penalty => (item.total_penalty_details || {})[penalty.key])
                                                                ).flat().map(v => v ? v.toFixed(2) : '').map((v, k, row) => (
                                                                    <td key={k}>{k === row.length - 1 ? (<b style={{color: 'red'}}>{v}</b>) : (<b style={{color: 'green'}}>{v}</b>)}</td>
                                                                ))}
                                                                */}
                                                                <td style={{color: 'blue'}}>{item?.total} {item?.rank ? ` / ${item?.rank}` : ''}</td>
                                                            </tr>
                                                            {tournament?.id == 33 && (
                                                                <tr><td colSpan="2">
                                                                  {Object.keys(item?.total_scores_details || {}).map(score_key => (
                                                                        <>
                                                                            {tournament?.definition_vote_settings?.settings?.scores.find(s => s.key === score_key)?.title[0]}:
                                                                            {(item?.total_scores_details[score_key].reduce((s, c) => s + c, 0)/(item?.total_scores_details[score_key].length)).toFixed(2)}
                                                                            &nbsp;&nbsp;&nbsp;
                                                                        </>
                                                                  ))}
                                                                  {Object.keys(item?.total_penalty_details || {}).map(penalty_key => {
                                                                      const penalty = tournament?.definition_vote_settings?.settings?.penalty.find(s => s.key === penalty_key)
                                                                      const values = item?.total_penalty_details[penalty_key]

                                                                      return (
                                                                          <>{penalty?.title[0]}: {(parseFloat(penalty?.parameters?.deduct_from || 0) + values.reduce((s, c) => s - c, 0)/values.length).toFixed(2)}&nbsp;&nbsp;&nbsp;</>
                                                                      )
                                                                  })}
                                                                </td></tr>
                                                            )}
                                                            {selected === item.id && (
                                                              <tr>
                                                                  <td colspan={2}>{title}</td>
                                                              </tr>
                                                                )
                                                            }
                                                            {selected === item.id && votes.map(vote => {




                                                              return (
                                                                <React.Fragment key={vote.id}>
                                                                    <tr>
                                                                        <td colspan={2}><b>{vote.profile ? vote.profile : vote.judge?.judge?.name}</b></td>
                                                                    </tr>


                                                                    {!!vote.comments && (
                                                                        <tr>
                                                                            <td colspan={2}><b>Коментар судді:</b> {vote.comments}</td>
                                                                        </tr>
                                                                    )}
                                                                    {!!vote.scores && !Array.isArray(vote.scores) && Object.keys(vote.scores).map((scoreKey, p) => (
                                                                        <React.Fragment key={p}>
                                                                            <tr>
                                                                                <td colSpan={2} align="center"><b>{getScoreTitle(scoreKey)}</b></td>
                                                                            </tr>
                                                                            {vote.scores[scoreKey].filter(f => f.score).map((scoreRow, scoreIndex) => (
                                                                                <tr key={scoreIndex} style={{fontSize: '0.9em'}}>
                                                                                    <td colSpan={1}>{scoreRow.label}</td>
                                                                                    <td style={{color: 'green'}}>{scoreRow.score.toFixed(2)} з {parseFloat(`${scoreRow.max}`).toFixed(2)}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </React.Fragment>
                                                                    ))}
                                                                    {!!vote.penalty && !Array.isArray(vote.penalty) && Object.keys(vote.penalty).map((penaltyKey, p) => (
                                                                        <React.Fragment key={p}>
                                                                            <tr>
                                                                                <td colSpan={2} align="center"><b>{getPenaltyTitle(penaltyKey)}</b></td>
                                                                            </tr>
                                                                            {vote.penalty[penaltyKey].filter(f => f.penalty).map((penaltyRow, penaltyIndex) => (
                                                                                <tr key={penaltyIndex} style={{fontSize: '0.9em'}}>
                                                                                    <td colSpan={1}>{penaltyRow.label}</td>
                                                                                    <td style={{color: 'red'}}>{penaltyRow.penalty.toFixed(2)}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </React.Fragment>
                                                            )})}
                                                        </React.Fragment>
                                                    )})}
                                                </React.Fragment>
                                            )})}
                                        </tbody>
                                    </table>
                                    )}

                                                                        {event.id === 3 && (
                                                                          <table style={{width: '100%'}}>
                                                                              <thead>
                                                                                  <tr>
                                                                                      <td><b>Номер</b></td>
                                                                                      <td><b>Учасник</b></td>
                                                                                      <td><b>Бали</b></td>
                                                                                  </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                                  {Object.values(grouped).map((items, i) => (
                                                                                      <React.Fragment key={i}>
                                                                                          <tr>
                                                                                              <td colSpan={2} align="center"><i>{items[0]?.definition}</i></td>
                                                                                          </tr>
                                                                                          {items.map((item, j) => (
                                                                                                  <tr key={j}>
                                                                                                      <td>{item?.number}</td>
                                                                                                      <td>{item?.participant?.name}</td>
                                                                                                      <td>{item?.total}</td>
                                                                                                  </tr>
                                                                                          ))}
                                                                                      </React.Fragment>
                                                                                  ))}
                                                                              </tbody>
                                                                        </table>
                                                                        )}

                                    {/*
                                    <div className="details-title-item">
                                        <div className="details-social-list d-flex align-items-center">
                                            <h6 className="title">Share :</h6>
                                            <div className="list-icon">
                                                <ul>
                                                    <li>
                                                        <a href="https://www.facebook.com/" rel="noreferrer" target="_blank"><i className="ri-facebook-fill"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="https://twitter.com/" rel="noreferrer"><i className="ri-twitter-fill"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.linkedin.com/" rel="noreferrer"><i className="ri-linkedin-fill"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.instagram.com/" rel="noreferrer"><i className="ri-instagram-fill"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    */}

                                    {/*
                                    <div className="metaverse-title">
                                        <h4 className="title fw-bold sc-mb-30">03 Comments</h4>
                                    </div>
                                    <div className="details-author-box d-flex">
                                        <div className="details-img">
                                            <img src={detailsAuthor1} alt="Blog" />
                                        </div>
                                        <div className="title-box">
                                            <div className="author-text">
                                                <h4 className="title">Esther Howard</h4>
                                                <div className="description">
                                                    Elit sed vulputate mi sit amet mauris commodo quis imperdiet. Nulla pellentesque dignissim enim sit. Vel facilisis volutpat est velit
                                                    egestas nibh sed pulvinar proin.
                                                </div>
                                            </div>
                                            <div className="details-btn">
                                                <Link smooth to="#" className="details-primary"><i className="ri-reply-line"></i> Replay</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details-author-box d-flex sc-ml-95">
                                        <div className="details-img">
                                            <img src={detailsAuthor2} alt="Blog" />
                                        </div>
                                        <div className="title-box">
                                            <div className="author-text">
                                                <h4 className="title">David Beckham</h4>
                                                <div className="description">
                                                    Nulla pellentesque enim sit. Vel facilisis est velit egestas nibh sed pulvinar proin. Elit sed mi sit amet mauris commodo quis
                                                    imperdiet.
                                                </div>
                                            </div>
                                            <div className="details-btn">
                                                <Link to="#" className="details-primary"><i className="ri-reply-line"></i> Replay</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="details-author-box d-flex">
                                        <div className="details-img">
                                            <img src={detailsAuthor3} alt="Blog" />
                                        </div>
                                        <div className="title-box">
                                            <div className="author-text">
                                                <h4 className="title">David Jhon</h4>
                                                <div className="description">
                                                    Vel facilisis volutpat est velit egestas nibh sed pulvinar proin. Elit sed vulputate mi sit amet mauris commodo quis imperdiet. Nulla
                                                    pellentesque dignissim enim sit.
                                                </div>
                                            </div>
                                            <div className="details-btn">
                                                <Link smooth to="#" className="details-primary"><i className="ri-reply-line"></i> Replay</Link>
                                            </div>
                                        </div>
                                    </div>
                                    */}
                                    {/*<BlogForm></BlogForm>*/}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <Sidebar></Sidebar>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogDetailsData;
